<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      height="100%"
      v-for="option in options"
      :key="option.Id"
    >
      <v-card
        outlined
        @click="chooseThisOption(option)"
        class="option wrapper_content_taxonomy hoverable"
      >
        <div class="option image">
          <v-img
            v-if="$vuetify.breakpoint.smAndDown"
            aspect-ratio="1"
            width="100px"
            height="100px"
            contain
            :src="option.Image"
          ></v-img>
          <v-img
            v-if="$vuetify.breakpoint.mdAndUp"
            aspect-ratio="1"
            width="200px"
            height="200px"
            contain
            :src="option.Image"
          ></v-img>
        </div>
        <div class="subtitle-1 font-weight-bold option">
          {{
            option.TranslationName ? $t(option.TranslationName) : option.Name
          }}
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["options"],
  data() {
    return {};
  },
  methods: {
    chooseThisOption(option) {
      this.$emit("chooseThisOption", option);
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  overflow-y: auto;
}
.wrapper_options {
  --auto-grid-min-size: 20rem;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}
.wrapper_content_taxonomy {
  /*--auto-grid-min-size: 25rem;*/
  display: grid;

  grid-template-columns: 1fr 5fr;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  transition: 0.2s all;
}

.wrapper_options:hover .wrapper_content_taxonomy {
  /* filter:blur(3px); */
  opacity: 0.8;
  transform: scale(0.98);
  box-shadow: none;
}
.wrapper_options:hover .wrapper_content_taxonomy:hover {
  transform: scale(1);
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
}
/* start solutions */
.wrapper_solutions {
  --auto-grid-min-size: 20rem;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}

.wrapper_content_solution {
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  transition: 0.2s all;
}

.wrapper_solutions:hover .wrapper_content_solution {
  /* filter:blur(3px); */
  opacity: 0.8;
  transform: scale(0.98);
  box-shadow: none;
}
.wrapper_solutions:hover .wrapper_content_solution:hover {
  transform: scale(1);
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
}
/* end solutions */
.option {
  border-radius: 5px;
  padding: 1em;
  height: 100%;
}
.option .image {
  padding: 0;
}
.wrapper_content_taxonomy {
  padding: 0em;
}

.solution {
  border-radius: 5px;
  padding: 1em;
  height: auto;
  min-height: 88px;
}
.sticky_solutionSelector {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px;
  z-index: 10;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>
