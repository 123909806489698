import axios from "axios";
import settings from "../settings/settings.js";
import router from "../router/index.js";
import qs from "qs";

const ajax = axios.create({
  baseURL: settings.oryxUrlApi,
});

ajax.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("auth");
      localStorage.removeItem("user");
      router.go();
    } else if (error.response.status === 404) {
      router.push({ name: "Error", params: { status: 404 } });
    } else if (error.response.status === 403) {
      router.push({ name: "Error", params: { status: 403 } });
    } else {
      //window.location.href = `${settings.baseUrl}/Error`
    }
    return Promise.reject(error);
  }
);

export default {
  steps(params) {
    return ajax.get(`${settings.oryxUrlApi}/Steps`, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    });
  },
  getSelectedSolution(params) {
    return ajax.get(`${settings.oryxUrlApi}/SelectedSolution`, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    });
  },
  getSelectedSolutionPdf(params) {
    return ajax.get(`${settings.oryxUrlApi}/SelectedSolution/Pdf`, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    });
  },
  getProductTypeChoices(lc) {
    return ajax.get(`${settings.oryxUrlApi}/ProductTypeChoices?lc=${lc}`);
  },
  getBrandChoices(lc) {
    return ajax.get(`${settings.oryxUrlApi}/BrandChoices?lc=${lc}`);
  },
  getProductChoicesByBrand(brandId, lc) {
    return ajax.get(
      `${settings.oryxUrlApi}/ProductChoicesByBrand?brandId=${brandId}&lc=${lc}`
    );
  },

  // SEARCHES
  getSearchByNumber(number) {
    return ajax.get(`${settings.oryxUrlApi}/Search/Number?number=${number}`);
  },
  getNumberResults(number) {
    return ajax.get(`${settings.oryxUrlApi}/Search/GetNumber?number=${number}`);
  },
  getSearchByName(name, lc) {
    return ajax.get(`${settings.oryxUrlApi}/Search/Name?name=${name}&lc=${lc}`);
  },
  getNameResults(productId) {
    return ajax.get(
      `${settings.oryxUrlApi}/Search/GetName?productId=${productId}`
    );
  },

  // FAVORITES
  getFavorites(userId) {
    return ajax.get(`${settings.oryxUrlApi}/Favorites/Get?userId=${userId}`);
  },
  createFavorite(favorites) {
    return ajax.post(`${settings.oryxUrlApi}/Favorites/Create`, favorites);
  },
  deleteFavorite(params) {
    console.log(params);
  },

  // //GET
  // step1() {
  //     return ajax.get(`${settings.oryxUrlApi}/Step1?lc=${localStorage.getItem("language")}`)
  // },

  // step2(t2) {
  //     return ajax.get(`${settings.oryxUrlApi}/Step2?tax2Id=${t2}&companyId=3&lc=${localStorage.getItem("language")}`)
  // },
  // step3(t2,t6) {
  //     return ajax.get(`${settings.oryxUrlApi}/Step3?tax2Id=${t2}&tax6id=${t6}&companyId=3&lc=${localStorage.getItem("language")}`)
  // },
  // step4(t2,t6,t3) {
  //     return ajax.get(`${settings.oryxUrlApi}/Step4?tax2Id=${t2}&tax6id=${t6}&tax3Id=${t3}&companyId=3&lc=${localStorage.getItem("language")}`)
  // },
  // step5(t2,t6,t3,t4) {
  //     return ajax.get(`${settings.oryxUrlApi}/Step5?tax2Id=${t2}&tax6id=${t6}&tax3Id=${t3}&tax4Id=${t4}&companyId=3&lc=${localStorage.getItem("language")}`)
  // },
  // step6(t2,t6,t3,t4,t1) {
  //     return ajax.get(`${settings.oryxUrlApi}/Step6?tax2Id=${t2}&tax6id=${t6}&tax3Id=${t3}&tax4Id=${t4}&tax1Id=${t1}&companyId=3&lc=${localStorage.getItem("language")}`)
  // },
  // step7(t2,t6,t3,t4,t1,t5) {
  //     return ajax.get(`${settings.oryxUrlApi}/Step7?tax2Id=${t2}&tax6id=${t6}&tax3Id=${t3}&tax4Id=${t4}&tax1Id=${t1}&tax5Id=${t5}&companyId=3&lc=${localStorage.getItem("language")}`)
  // },
};
