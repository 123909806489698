import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Favorites",
      name: "Favorites",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "favorites" */ "../views/Favorites.vue"),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/Faq",
      name: "Faq",
      component: () => import(/* webpackChunkName: "faq" */ "../views/FAQ.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Contact",
      name: "Contact",
      component: () =>
        import(/* webpackChunkName: "faq" */ "../views/Contact.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Login",
      name: "Login",
      component: () =>
        import(/* webpackChunkName: "login" */ "../views/Login.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/Choice",
      name: "Choice",
      component: () =>
        import(/* webpackChunkName: "choice" */ "../views/Choice.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ProductTypeChoice",
      name: "ProductTypeChoice",
      component: () =>
        import(
          /* webpackChunkName: "productTypeChoice" */ "../views/ProductTypeChoice.vue"
        ),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/BrandChoice",
      name: "BrandChoice",
      component: () =>
        import(
          /* webpackChunkName: "brandChoice" */ "../views/BrandChoice.vue"
        ),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/ProductChoice",
      name: "ProductChoice",
      component: () =>
        import(
          /* webpackChunkName: "productChoice" */ "../views/ProductChoice.vue"
        ),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/Selector",
      name: "Selector",
      component: () =>
        import(/* webpackChunkName: "selector" */ "../views/Selector.vue"),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/Detail",
      name: "Detail",
      component: () =>
        import(/* webpackChunkName: "detail" */ "../views/Detail.vue"),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (localStorage.getItem("auth") == null) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
