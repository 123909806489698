import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

// import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0a3051",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
        highlight: "#009bd9",
        subHighlight: "#85c9f0",
        purpleColor: "#4a2030",
        greenColor: "#496c6f",
        greyColor: "#f2f5f8",
      },
    },
  },
});
