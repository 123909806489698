<template>
  <choiceOptions :options="data" @chooseThisOption="chooseThisOption" />
</template>

<script>
import jwtdecode from "jwt-decode";
import choiceOptions from "@/components/ChoiceOptions";

export default {
  components: {
    choiceOptions,
  },
  data() {
    return {
      data: [
        {
          Description: null,
          Id: 0,
          Index: 0,
          Image: require("../assets/Constructie.png"),
          // "https://w7.pngwing.com/pngs/861/197/png-transparent-computer-icons-architectural-engineering-building-construction-worker-construction-site-hat-building-text-thumbnail.png",
          Name: null,
          TranslationName: "selectSolutionBaseConstruction",
          To: "Selector",
        },
        {
          Description: null,
          Id: 1,
          Index: 1,
          Image: require("../assets/ProductType.png"),
          // "https://www.pngfind.com/pngs/m/131-1312918_png-file-svg-product-icon-transparent-png.png",
          Name: null,
          TranslationName: "selectSolutionBaseProductType",
          To: "ProductTypeChoice",
        },
        {
          Description: null,
          Id: 2,
          Index: 2,
          Image: require("../assets/Fabriek.png"),
          // "https://ecommerce-platforms.com/wp-content/uploads/2019/05/Manufacturer.png",
          Name: null,
          TranslationName: "selectSolutionBaseBrand",
          To: "BrandChoice",
        },
      ],
    };
  },
  methods: {
    chooseThisOption(option) {
      this.$store.commit("resetSolutionSelectorChoices");
      this.$store.commit("resetSelectedSolution");
      this.$router.push({
        name: option.To,
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  overflow-y: auto;
}
.wrapper_options {
  --auto-grid-min-size: 20rem;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}
.wrapper_content_taxonomy {
  /*--auto-grid-min-size: 25rem;*/
  display: grid;

  grid-template-columns: 1fr 5fr;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  transition: 0.2s all;
}

.wrapper_options:hover .wrapper_content_taxonomy {
  /* filter:blur(3px); */
  opacity: 0.8;
  transform: scale(0.98);
  box-shadow: none;
}
.wrapper_options:hover .wrapper_content_taxonomy:hover {
  transform: scale(1);
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
}
/* start solutions */
.wrapper_solutions {
  --auto-grid-min-size: 20rem;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}

.wrapper_content_solution {
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  transition: 0.2s all;
}

.wrapper_solutions:hover .wrapper_content_solution {
  /* filter:blur(3px); */
  opacity: 0.8;
  transform: scale(0.98);
  box-shadow: none;
}
.wrapper_solutions:hover .wrapper_content_solution:hover {
  transform: scale(1);
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
}
/* end solutions */
.option {
  border-radius: 5px;
  padding: 1em;
  height: 100%;
}
.option .image {
  padding: 0;
}
.wrapper_content_taxonomy {
  padding: 0em;
}

.solution {
  border-radius: 5px;
  padding: 1em;
  height: auto;
  min-height: 88px;
}
.sticky_solutionSelector {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px;
  z-index: 10;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>
