/* eslint-disable no-dupe-keys */
const locales = {
  EN: {
    // name: "Name",
    // building: "Building name",
    // description: "Description",
    // order: "Order",
    // status: "Status",
    // actions: "Actions",
    // marker: "Marker",
    // markerName: "Marker name",
    // class: "Class",
    // buildingComponent: "BuildingComponent",
    // buildingComponents: "BuildingComponents",
    // createdTs: "Creation date",
    // blueprints: "Blueprints",
    // markers: "Markers",
    // markUp: "Manage markers on blueprint",
    // values: "Values",
    // inUse: "In Use",
    // firstname: "First name",
    // building: "Building name",
    // marker: "Marker name",
    // description: "Description",
    disclaimer: "Additional information",
    // street: "Street",
    // number: "No.",
    // postalcode: "Postcal code",
    // city: "City",
    // country: "Country",
    // mobile: "Mobile",
    email: "Email",
    // date: "Application date",
    // order: "Order",
    // class: "Class",
    // attribute: "Attribute",
    // value: "value",
    // installer: "Installer",
    favorites: "Favorites",
    chooseProductType: "Choose a producttype.",
    chooseBrand: "Choose a brand.",
    selectSolutionBaseConstruction: "Solution based on construction.",
    selectSolutionBaseProductType: "Solution based on producttype.",
    selectSolutionBaseBrand: "Solution based on brand.",
    // solution: "Solution",
    // solutions: "Solutions",
    // username: "Username",
    password: "Password",
    // uploadBlueprintFile: "Upload blueprint file (.jpg file)",
    // comments: "Comments",
    // products: "Products",
    // approved: "Approved",
    // broken: "Broken",
    // dimensions: "Dimensions",
    width: "Width",
    // height: "Height (mm)",
    // length: "Length (mm)",
    diameter: "Diameter",
    // unit: "Unit",
    // amount: "Amount",
    // details: "Details",
    required: "is required",
    invalid: "is invalid",
    // warning: "Warning",
    // qrIdExists: "Error",
    // success: "Success",
    // cached: "Offline",
    // saveChanges: "Save changes?",
    // overrideQRId:
    //   "This service penetration is already linked with a qr code.Do you want to assign it with a new qr code?",
    // qrIdExists:
    //   "This qr code can not be applied. A different service penetration uses this qr code!",
    // success:
    //   "This qr code is successfully linked with this service penetration!",
    // areYouSure: "Are you sure you want to delete this?",
    // cached: "This services penetration will be linked once you go online",
    // unsaved: "Changes were made. Do you want to save changes?",
    // yes: "Yes",
    // no: "No",
    // currentPassword: "Current password",
    // newPassword: "New password",
    // confirmPassword: "Confirm password",
    // changePassword: "Change password",
    // active: "Active",
    // noBlueprint: "I currently don't have a blueprint",
    // deadline: "Deadline",
    // newProject: "New Project",
    // newBuildingComponent: "New Building Component",
    // newBlueprint: "New Blueprint",
    save: "Save",
    // add: "Add",
    close: "Close",
    login: "Sign in",
    // newInstaller: "Add installer",
    // addPicture: "Add picture",
    // newSolution: "Add solution",
    // addQRCode: "Link QR code",
    // offline: "Offline",
    // addMarkers: "Add Markers",
    // cancel: "Cancel",
    download: "Download",
    // edit: "Edit",
    // addProduct: "Add product",
    home: "Home",
    // projects: "Projects",
    // buildingComponents: "Building Components",
    // blueprints: "Blueprints",
    // markers: "Markers",
    // project: "Project",
    // buildingComponent: "Building Component",
    // blueprint: "Blueprint",
    // marker: "Marker",
    // address: "Address",
    // contact: "Contact",
    // settings: "Settings",
    // back: "Back",
    // installer: "Installer",
    // users: "Users",
    // camera: "Camera",
    // solutions: "Solutions",
    // solution: "Solution",
    products: "Products",
    // product: "Product",
    // todo: "To do",
    userNotFound: "The password you entered is incorrect.",
    // selectSolution: "Please select a solution",
    success: "Success",
    error: "Something went wrong",
    // cached: "Changes will be saved once you are online",
    // unsaved: "Changes were made, don't forget to save before you leave.",
    // offlineUsers: "is working offline on blueprint: ",
    // notOnline: "Please connect to the internet",
    // inReadOnly: "Currently in readonly mode",
    // newSolution: "New Solution",
    // chooseSolution: "Choose a solution",
    // draw: "Draw on the blueprint to create a marker!",
    // wait1: "Loading...",
    // wait2: "Preparing Final Form",
    // todo: "To do",
    // deadline: "Deadline",
    // reports: "Reports",
    // qrcodeNotLinked:
    //   "This qr code is not yet linked, or is linked with markers not on this blueprint.",
    // scanQRCode: "Scan a linked QR code",
    // attachment: "Attachment",
    // isEditing: "Is editing",
    // editStart: "Started editing",
    noaccess: "No access",
    // fillinrequiredfields: "Fill in the required fields",
    // withlogo: "With logo",
    // withoutlogo: "Without logo",
    // search: "Search",
    // chooseFile: "Choose file",
    // continuereadonly:
    //   "Someone is working on this blueprint. Do you still want to visit the page without having the ability to edit?",
    // blueprintLocked: "is busy editing",
    // customsolutions: "Custom solutions",
    // noproducts: "No products",
    // addpicture: "Add picture",
    // help: "Help",
    noresults: "No results",
    signout: "Sign out",
    viewMore: "View more",
    usethisinmarker: "Use this in marker",
    // addyourownlogo: "add you own logo",
    // delete: "delete",
    step: "Step",
    // application: "Application",
    solutionselector: "Solution Selector",
    selectsolution: "Select Solution",
    // inProcess: "In process",
    // solutionsList: "Solutions list",
    // deleteSolution: "Delete solution",
    // selectUsedProducts: "Select used products",
    // showAll: "Show all",
    // new: "New",
    useSolutionSelector: "Find solution using the solution selector",
    // orUseDropdown: "Or find it in the list",
    // useDropdown: "Find solution int the list",
    etaOrReportNumber: "ETA or Reportnumber",
    norm: "Norm",
    bracketDistance: "Bracket distance",
    material: "Material",
    secure: "Secure",
    juncture: "Juncture",
    minMaxAngle: "Minimum-maximum angle",
    distanceTo: "Distance to",
    sameType: "same type",
    others: "others",
    edge: "edge",
    dimensions: "Dimensions",
    maxOpening: "Maximum opening",
    nrOfLayers: "Number of layers",
    apply: "Apply",
    situation: "Situation",
    alongWith: "Along with",
    productSolution: "Product solution",
    insulationThickness: "Insulation thickness",
    insulationLength: "Insulation length",
    insulationForm: "Insulation form",
    brand: "Brand",
    capping: "Capping",
    wallThickness: "Wall thickness",
    thickness: "Thickness",
    fireResistance: "Fire resistance",
    minutes: "minutes",
    chooseLanguage: "Choose your language",
    noFavorites: "No favorites.",
    chooseStart: "Choose where you want to start.",
    chooseProduct: "Choose a product",
    searchingForNumber:
      "You are searching by number. Click here if you want to search by name.",
    searchingForName:
      "You are searching by name. Click here if you want to search by number.",
    FAQ: "FAQ",
    contact: "Contact",
    solutionSelectorBy: "Solution selector by",
    contactUs: "Contact us",
    askTestAccount: "Request a test account",
    contactOpeningHours: "Opening hours",
    contactPageTitle: "Vragen? Neem dan contact met ons op.",
    contactPageText:
      "<br>Heb je vragen over de Firelog Solution Selector? Of wens je een online demo?</br>Neem dan vrijblijvend contact met ons op.</p>",
  },
  NL: {
    // general
    save: "Opslaan",
    close: "Sluiten",
    download: "Downloaden",
    success: "Succes",
    error: "Error",
    required: "is vereist.",
    invalid: "is niet geldig.",
    noaccess: "Geen toegang",
    noresults: "Geen resultaten",
    disclaimer: "Aanvullende info",
    // solution selector
    installer: "Monteur",
    chooseProductType: "Kies een producttype.",
    chooseBrand: "Kies een merk.",
    selectSolutionBaseConstruction: "Oplossing op basis van constructie.",
    selectSolutionBaseProductType: "Oplossing op basis van producttype.",
    selectSolutionBaseBrand: "Oplossing op basis van merk.",
    width: "Breedte",
    diameter: "Diameter",
    viewMore: "Toon meer info",
    products: "Producten",
    usethisinmarker: "Gebruik dit in marker",
    step: "Stap",
    solutionselector: "Solution Selector",
    selectsolution: "Kies een oplossing",
    useSolutionSelector:
      "Zoek de juiste oplossing met behulp van de solution selector",
    etaOrReportNumber: "ETA of Rapportnummer",
    norm: "Norm",
    bracketDistance: "Beugelafstand",
    material: "Materiaal",
    secure: "Bevestigen",
    juncture: "Naad",
    minMaxAngle: "Minimale-maximale hoek",
    distanceTo: "Afstand tot",
    sameType: "zelfde type",
    others: "anderen",
    edge: "rand",
    dimensions: "Maatvoeringen",
    maxOpening: "Maximale opening",
    nrOfLayers: "Aantal lagen",
    apply: "Aanbrengen",
    situation: "Situatie",
    alongWith: "Samen met",
    productSolution: "Product oplossing",
    insulationThickness: "Isolatie dikte",
    insulationLength: "Isolatie lengte",
    insulationForm: "Isolatie vorm",
    brand: "Merk",
    capping: "Capping",
    wallThickness: "Wanddikte",
    thickness: "Dikte",
    fireResistance: "Brandwerendheid",
    minutes: "minuten",
    chooseLanguage: "Kies uw taal",
    noFavorites: "Geen favorieten.",
    chooseStart: "Kies waar je wilt beginnen.",
    chooseProduct: "Kies een product.",
    // navbar
    searchingForNumber:
      "Je bent aan het zoeken op nummer. Klik hier als je wilt zoeken op naam.",
    searchingForName:
      "Je bent aan het zoeken op naam. Klik hier als je wilt zoeken op nummer.",
    solutionSelectorBy: "Solution selector by",
    home: "Home",
    favorites: "Favorieten",
    FAQ: "FAQ",
    contact: "Contact",
    signout: "Uitloggen",
    // login page
    register: "Nog geen account?",
    login: "Aanmelden",
    email: "Email",
    password: "Wachtwoord",
    userNotFound: "Het ingevoerde wachtwoord is onjuist",
    // contact page
    contactUs: "Contacteer ons",
    askTestAccount: "Vraag een testaccount aan",
    contactOpeningHours: "Openingsuren",
    contactPageTitle: "Vragen? Neem dan contact met ons op.",
    contactPageText:
      "<br>Heb je vragen over de Firelog Solution Selector? Of wens je een online demo?</br>Neem dan vrijblijvend contact met ons op.</p>",
  },
  DE: {
    // name: "Name",
    // building: "Gebäudename",
    // description: "Beschreibung",
    // order: "Reihenfolge",
    // status: "Status",
    // actions: "Tätigkeiten",
    // marker: "Markierung",
    // markerName: "Markierungsbezeichnung",
    // class: "Klasse",
    // buildingComponent: "Bauabschnitt",
    // buildingComponents: "Bauteile",
    // createdTs: "Erstellungsdatum",
    // blueprints: "Geschosse",
    // markers: "Markierungen",
    // markUp: "Markierung im Bauplan setzen",
    // values: "Wert",
    // inUse: "In Gebrauch",
    // name: "Name",
    // firstname: "Vorname",
    // building: "Gebäudename",
    // marker: "Markierungsbezeichnung",
    // description: "Beschreibung",
    // street: "Straße",
    // number: "Nummer",
    // postalcode: "Postleitzahl",
    // city: "Ort",
    // country: "Land",
    // mobile: "Handynummer",
    // email: "Emailadresse",
    // date: "Installationsdatum",
    // order: "Reihenfolge",
    // class: "Klasse",
    // attribute: "Eigenschaft",
    // value: "Wert",
    // installer: "Installateur",
    // solution: "Lösung",
    // solutions: "Lösungen",
    // username: "Benutzername",
    // password: "Passwort",
    // uploadBlueprintFile: "Hochladen von bauplan (.jpg Datei)",
    // comments: "Kommentare",
    // products: "Produkte",
    // approved: "Genehmigt",
    // broken: "Defekt",
    // dimensions: "Abmessungen",
    // width: "Breite (mm)",
    // height: "Höhe (mm)",
    // length: "Länge (mm)",
    // diameter: "Durchmesser (mm)",
    // unit: "Einheit",
    // amount: "Menge",
    // details: "Details",
    // required: "vorgeschrieben",
    // invalid: "ungültig",
    // overrideQRId: "Achtung",
    // qrIdExists:
    //   "Dieser QR Code kann nicht genutzt werden. Eine andere Abschottung hat bereits diesen QR Code zugewiesen bekommen",
    // success: "Erfolgreich",
    // saveChanges: "Änderungen sichern?",
    // overrideQRId:
    //   "Diese Abschottung ist bereits mit einem QR Code verknüpft. Möchten Sie der Abschottung einen neuen QR Code zuordnen?",
    // qrIdExists:
    //   "Dieser qr Code kann nicht genutzt werden. Eine andere Abschottung hat bereits diesen qr Code.",
    // success: "Der qr Code wurde erfolgreich mit der Abschottung verknüpft.",
    // areYouSure: "Sind Sie sicher das Sie diese Angabe löschen möchten?",
    // cached: "Diese Abschottung wird verknüft sobald sie wieder Online sind.",
    // unsaved: "Änderungen erfolgreich. Möchten Sie die Änderungen speichern?",
    // yes: "Ja",
    // no: "Nein",
    // currentPassword: "Aktuelles Passwort",
    // newPassword: "Neues Passwort",
    // confirmPassword: "Passwort bestätigen",
    // changePassword: "Passwort ändern",
    // active: "Activ",
    // noBlueprint: "Aktuell gibt es keine Baupläne.",
    // deadline: "Enddatum",
    // newProject: "Neues Projekt",
    // newBuildingComponent: "Neues Bauteil",
    // newBlueprint: "Neuer Entwurf",
    // save: "Speichern",
    // add: "Ergänzen",
    // close: "Schließen",
    // login: "Einloggen",
    // newInstaller: "Installateuer hinzufügen",
    // addPicture: "Bild hinzufügen",
    // newSolution: "Lösung hinzufügen",
    // addQRCode: "Link QR code",
    // offline: "Offline",
    // addMarkers: "Marker hinzufügen",
    // cancel: "Stornieren",
    // download: "Download",
    // edit: "Bearbeiten",
    // addProduct: "Product hinzufügen",
    // home: "Home",
    // projects: "Projekte",
    // buildingComponents: "Bauteile",
    // markers: "Markierungen",
    // project: "Projekt",
    // buildingComponent: "Bauteil",
    // blueprint: "Bauplan",
    // marker: "Markierung",
    // address: "Adresse",
    // contact: "Kontakt",
    // settings: "Einstellungen",
    // back: "Zurück",
    // installer: "Installateur",
    // users: "Benutzer",
    // addQRCode: "QR Code Scanner",
    // camera: "Kamera",
    // solutions: "Lösungen",
    // solution: "Lösung",
    // products: "Produkte",
    // product: "Produkt",
    // userNotFound: "Das eigegebene Passwort ist nicht korrekt.",
    // selectSolution: "Bitte wählen Sie eine Lösung",
    // success: "Erfolgreicht",
    // error: "Etwas ist schief gegangen",
    // cached: "Änderungen werden gespeichert sobald Sie Online sind. ",
    // unsaved:
    //   "Änderungen sind eingegeben, bitte vergessen Sie nicht diese zu speichern bevor Sie das Programm verlassen. ",
    // offlineUsers: "Arbeitet Offline am Bauplan: ",
    // notOnline: "Bitte verbinden Sie sich mit dem Internet",
    // inReadOnly: "Derzeit schreibgeschützt",
    // newSolution: "Neue Lösung",
    // chooseSolution: "Wählen Sie eine Lösung",
    // draw: "Klicken Sie auf den Bauplan um den Marker zu setzen. ",
    // wait1: "Lädt...",
    // wait2: "Finale Vorlage wird erstellt",
    // todo: "Aufgaben für Monteure",
    // reports: "Meldungen",
    // qrcodeNotLinked:
    //   "Dieser QR Code ist nicht verknüpft oder mit Markierungen verbunden die sich nicht auf diesem Bauplan befinden. ",
    // scanQRCode: "Einen bereits verknüpften QR Code scannen.",
    // attachment: "Anhang",
    // isEditing: "in Bearbeitung",
    // editStart: "Bearbeitung gestartet",
    // noaccess: "kein Zugang",
    // fillinrequiredfields: "Füllen Sie die erforderlichen Felder aus",
    // withlogo: "Mit logo",
    // withoutlogo: "Ohne logo",
    // search: "Suchen",
    // chooseFile: "Datei wählen",
    // continuereadonly:
    //   "Jemand bearbeitet diese Bauplan. Möchten Sie die Seite trotzdem besuchen, ohne die Möglichkeit zu haben, sie zu bearbeiten?",
    // blueprintLocked: "arbeitet an diese Blauplan",
    // customsolutions: "Eigene Systeme",
    // noproducts: "Keine Produkte",
    // addpicture: "Bild hinzufügen",
    // help: "Hilfe",
    // noresults: "Keine Ergebnisse",
    // signout: "Abmelden",
    // viewMore: "Mehr sehen",
    // usethisinmarker: "Benutze dies in marker",
    // addyourownlogo: "Fügen Sie Ihr eigenes Logo hinzu",
    // delete: "Löschen",
    // step: "Schritt",
    // application: "Installationsanleitung",
    // solutionselector: "Solution Selector",
    // selectsolution: "Wählen Sie eine Lösung",
    // inProcess: "In Bearbeitung",
    // solutionsList: "Lösungsliste",
    // deleteSolution: "Lösung löschen",
    // selectUsedProducts: "Gebrauchte Produkte auswählen",
    // showAll: "Zeige alles",
    // new: "Nue",
    // useSolutionSelector:
    //   "Finden Sie die richtige Lösung mit dem solution selector",
    // orUseDropdown: "Oder durchsuchen Sie die Liste",
    // useDropdown: "Wählen Sie die richtige Lösung aus dieser Liste",
  },
  NN: {
    // name: "Navn",
    // building: "Byggets navn",
    // description: "Beskrivelse",
    // order: "Ordre",
    // status: "Status",
    // actions: "Aktivitet",
    // marker: "Markør",
    // markerName: "Markør navn",
    // class: "Klasse",
    // buildingComponent: "Byggkomponent",
    // buildingComponents: "Byggkomponenter",
    // createdTs: "Opprettelsesdato",
    // markers: "Markør",
    // markUp: "Markør brukes på plantegning",
    // values: "Verdier",
    // inUse: "I bruk",
    // firstname: "Fornavn",
    // building: "Byggets navn",
    // marker: "Markør name",
    // description: "Beskrivelse",
    // street: "Gate",
    // number: "nr",
    // postalcode: "Postnr.",
    // city: "By",
    // country: "Land",
    // mobile: "Mobil",
    // email: "E-post",
    // date: "Applikasjonsdato",
    // attribute: "Egenskap",
    // value: "Verdi",
    // installer: "Installatør",
    // solution: "Solution",
    // solutions: "Løsninger",
    // username: "Brukernavn",
    // password: "Passord",
    // uploadBlueprintFile: "Last opp plantegning (.jpg fil)",
    // comments: "Kommentar",
    // approved: "Godkjent",
    // broken: "i stykker",
    // dimensions: "Dimensjon",
    // width: "Bredde (mm)",
    // height: "Høyde (mm)",
    // length: "Lengde (mm)",
    // diameter: "Diameter (mm)",
    // unit: "Enhet",
    // amount: "Antall",
    // details: "Detaljer",
    // required: "påkrevd",
    // invalid: "ugyldig",
    // overrideQRId: "Advarsel",
    // qrIdExists: "Feil",
    // success: "Vellykket",
    // cached: "Offline",
    // saveChanges: "Lagre endringer?",
    // overrideQRId:
    //   "Denne gjennomføringen er allerede knyttet til en QR-kode. Vil du tilordne den med en ny QR-Kode?",
    // qrIdExists:
    //   "QR-koden kan ikke brukes. En annen gjennomføring bruker QR-koden!",
    // success: "QR-koden er koblet opp med  gjenomføringen!",
    // areYouSure: "Er du sikker å slette?",
    // cached: "jennomføring vil kobles opp automatisk når du er online.",
    // unsaved: "Det ble gjort endringer. Vil du beholde endringene?",
    // yes: "Ja",
    // no: "Nei",
    // currentPassword: "Gjeldende passord",
    // newPassword: "Nytt passord",
    // confirmPassword: "Bekreft passord",
    // changePassword: "Endre passord",
    // active: "Aktiv",
    // newProject: "Nytt prosjekt",
    // newBuildingComponent: "Ny byggkomponent",
    // newBlueprint: "Ny plantegning",
    // save: "Lagre",
    // add: "Legg til",
    // close: "Lukk",
    // login: "Logg inn",
    // newInstaller: "Legg til installatør",
    // addPicture: "Legg til bildet",
    // newSolution: "Legg til løsning",
    // addQRCode: "Link QR Kode",
    // offline: "Offline",
    // addMarkers: "Legg til  markør",
    // cancel: "Avbryt",
    // download: "Last ned",
    // edit: "Rediger",
    // addProduct: "Legg til produkt",
    // home: "Hjem",
    // projects: "Prosjekter",
    // blueprints: "Plantegninger",
    // project: "Prosjekt",
    // blueprint: "Plantegning",
    // marker: "Markør",
    // address: "Adresse",
    // contact: "Kontakt",
    // settings: "Instillinger",
    // back: "Tilbake",
    // installer: "Installatør",
    // users: "Brukere",
    // camera: "Kamera",
    // solutions: "Løsninger",
    // solution: "Løsning",
    // products: "Produkte",
    // product: "Produkt",
    // userNotFound: "Passordet du oppga er feil.",
    // selectSolution: "Velg en løsning",
    // success: "Vellykket",
    // error: "Noe gikk galt",
    // cached: "Endringer lagres når du er online",
    // unsaved: "Det ble gjort endringer, ikke glem å lagre før du avslutter",
    // offlineUsers: "fungerer offline på plantegning: ",
    // notOnline: "Vennligst koble til internett",
    // inReadOnly: "for øyeblikket i readonly-modus",
    // newSolution: "Ny løsning",
    // chooseSolution: "Velg løsning",
    // draw: "Trykk på plantegning for å lage en markør!",
    // wait1: "Laster...",
    // wait2: "Forbereder endelig skjema",
    // qrcodeNotLinked:
    //   "This qr code is not yet linked, or is linked with markers not on this blueprint.",
    // scanQRCode: "Scan a linked QR code",
    // attachment: "Feste",
    // isEditing: "Is editing",
    // editStart: "Started editing",
    // noaccess: "Ingen adgang",
    // fillinrequiredfields: "fyll ut de nødvendige feltene",
    // withlogo: "Med logo",
    // withoutlogo: "Uten logo",
    // search: "Søk",
    // chooseFile: "Velg Fil",
    // continuereadonly:
    //   "Noen jobber med denne plantegning. Vil du fremdeles besøke siden uten å kunne redigere?",
    // blueprintLocked: "er opptatt med å redigere",
    // customsolutions: "tilpassede løsninger",
    // noproducts: "Ingen produkter",
    // addpicture: "Legg til bilde",
    // help: "Hjelp",
    // noresults: "Ingen resultater",
    // signout: "Logg ut",
    // viewMore: "Ser mer",
    // usethisinmarker: "Bruke det i markør",
    // addyourownlogo: "legg til din egen logo",
    // delete: "slette",
    // step: "Skritt",
    // application: "Installasjonsinstruksjoner",
    // solutionselector: "Solution Selector",
    // selectsolution: "Velg en løsning",
    // inProcess: "Igang",
    // solutionsList: "Løsningsliste",
    // deleteSolution: "Slette løsning",
    // selectUsedProducts: "Velg brukte produkter",
    // showAll: "Ny",
    // useSolutionSelector: "Finn riktig løsning med solution selector",
    // orUseDropdown: "Eller søk i listen",
    // useDropdown: "Velg riktig løsning fra denne listen",
  },
  SV: {
    // name: "Name",
    // building: "Byggnadens namn",
    // description: "Beskrivning",
    // order: "Order",
    // status: "Status",
    // actions: "Utförande",
    // marker: "Markering",
    // markerName: "Markeringens namn",
    // class: "Klass",
    // buildingComponent: "Byggnadsdel",
    // buildingComponents: "Byggnadsdelar",
    // createdTs: "Skapat datum",
    // blueprints: "Ritningar",
    // markers: "Markörer",
    // markUp: "Hantera markörer på ritning",
    // values: "Värde",
    // inUse: "I användning",
    // firstname: "Förnamn",
    // building: "Byggnadens namn",
    // street: "Gata",
    // number: "Nummer",
    // postalcode: "Postnummer",
    // city: "Stad",
    // country: "Land",
    // mobile: "Mobil",
    // email: "E-post",
    // date: "Ansökningsdatum",
    // order: "Ordningen",
    // class: "Klass",
    // attribute: "Egenskap",
    // value: "Värde",
    // installer: "Installatör",
    // username: "Användarnamn",
    // password: "Lösenord",
    // uploadBlueprintFile: "Ladda upp ritnings fil (.jpg fil)",
    // comments: "Kommentarer",
    // products: "Produkt",
    // approved: "Godkänd",
    // broken: "Trasig",
    // dimensions: "Dimensioner",
    // width: "Bredd (mm)",
    // height: "Höjd (mm)",
    // length: "Längd (mm)",
    // diameter: "Diameter (mm)",
    // unit: "Enhet",
    // amount: "Belopp",
    // details: "Detaljer",
    // required: "Krävs",
    // invalid: "Är ogiltigt",
    // overrideQRId: "Varning",
    // qrIdExists: "Fel",
    // success: "Framgång",
    // cached: "Offline",
    // saveChanges: "Spara ändring?",
    // overrideQRId:
    //   "Denna brandgenomföring är redan kopplad till en QR-kod. Vill du tilldela den med en ny QR-kod?",
    // qrIdExists:
    //   "Denna QR-kod kan inte tillämpas. En annan brandgenomföring använder denna QR-kod!",
    // success: "Denna QR-kod kopplas framgångsrikt med denna brandgenomföring!",
    // areYouSure: "Är du säker på att du vill ta bort det här?",
    // cached: "Denna brandgenomföring kommer att uppdateras när du går online",
    // unsaved: "Ändringar gjorda. Vill du spara ändringar?",
    // yes: "Ja",
    // no: "Nej",
    // currentPassword: "Nuvarande lösenord",
    // newPassword: "Nytt lösenord",
    // confirmPassword: "Bekräfta lösenord",
    // changePassword: "Byta lösenord",
    // active: "Aktiv",
    // newProject: "Nytt projekt",
    // newBuildingComponent: "Ny byggnadskomponent",
    // newBlueprint: "Ny ritning",
    // save: "Spara",
    // add: "Lägga till",
    // close: "Stänga",
    // login: "Logga in",
    // newInstaller: "Lägg till Installatör",
    // addPicture: "Lägg till bild",
    // newSolution: "Lägg till lösning",
    // addQRCode: "Länk till QR-kod",
    // offline: "Offline",
    // addMarkers: "Lägg till markörer",
    // cancel: "Avbryt",
    // download: "Ladda ner",
    // edit: "Redigera",
    // addProduct: "Lägg till product",
    // home: "Hem",
    // projects: "Projekt",
    // project: "Project",
    // blueprint: "Ritning",
    // marker: "Markering",
    // address: "Adress",
    // contact: "Kontakt",
    // settings: "Inställningar",
    // back: "Tillbaka",
    // installer: "Installatörer",
    // users: "Användare",
    // camera: "Kamera",
    // solutions: "Lösningar",
    // solution: "Lösning",
    // products: "Produkter",
    // product: "Produkter",
    // userNotFound: "Lösenordet som angavs är felaktigt.",
    // selectSolution: "Välj en lösning",
    // success: "Framgång",
    // error: "Något gick fel",
    // cached: "Ändringar sparas när du är online",
    // unsaved: "Ändringar gjordes, glöm inte att spara innan du lämnar.",
    // offlineUsers: "Arbetet med ritningen sker offline:",
    // notOnline: "Anslut till internet",
    // inReadOnly: "för närvarande i readonly-läge",
    // newSolution: "Ny lösning",
    // chooseSolution: "Välj en  lösning",
    // draw: "Rita på ritningen för att skapa en markering!",
    // wait1: "Laddar...",
    // wait2: "Förbereder slutlig utformning",
    // qrcodeNotLinked:
    //   "This qr code is not yet linked, or is linked with markers not on this blueprint.",
    // scanQRCode: "Scan a linked QR code",
    // attachment: "anknytning",
    // isEditing: "Redigerar",
    // editStart: "Började redigera",
    // noaccess: "Ingen åtkomst",
    // fillinrequiredfields: "fyll i de obligatoriska fälten",
    // withlogo: "med logotyp",
    // withoutlogo: "Utan logotyp",
    // search: "Sök",
    // chooseFile: "Välj FIL",
    // continuereadonly:
    //   "Någon arbetar med denna plan. Vill du fortfarande besöka sidan utan att kunna redigera?",
    // blueprintLocked: "är upptagen med att redigera",
    // customsolutions: "Anpassade lösningar",
    // noproducts: "Inga produkter",
    // addpicutre: "Lägg till bild",
    // help: "Hjälp",
    // noresults: "Inga resultat",
    // signout: "Logga ut",
    // viewMore: "Visa mer",
    // usethisinmarker: "Använd detta i markören",
    // addyourownlogo: "lägg till din egen logotyp",
    // delete: "radera",
    // tep: "Steget",
    // application: "Installationsinstruktioner",
    // solutionselector: "Solution Selector",
    // selectsolution: "Välj en lösning",
    // inProcess: "Pågående",
    // solutionsList: "Lösningar lista",
    // deleteSolution: "ta bort lösningen",
    // selectUsedProducts: "Välj begagnade produkter",
    // showAll: "Visa allt",
    // new: "Ny",
    // useSolutionSelector: "Hitta rätt lösning med solution selector",
    // orUseDropdown: "Eller sök i listan",
    // useDropdown: "Välj rätt lösning från listan",
  },
};

export default locales;
