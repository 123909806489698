import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    loggedIn: false,
    isMobile: false,
    isOffline: false,
    rows: [15, 50, 100],
    colors: {
      primary: "#0A3051",
      highlight: "#009BD9",
      subhighlight: "#85C9F0",
      error: "#f44336",
      green: "#176e4b",
      orange: "#F5A800",
      grey: "#DCDCDC",
    },
    previous: null,
    offline: {
      offBlueprint: null,
      offMarkers: [],
      offProductSolutions: null,
      offInstallers: null,
      offUnusedAttributes: null,
    },
    offForm: [],
    offAttachments: [],
    offQrs: [],
    markers: [],
    productSolutions: null,
    blueprintImg: null,
    hasChanges: false,
    scale: null,
    drawable: false,
    offlineBlueprints: [],
    snapshot: null,
    languages: null,
    solutionSelectorChoices: {
      productType: {},
      brand: {},
      product: {},
    },
    selectedSolution: {
      id: null,
      taxId: null,
    },
  },

  mutations: {
    addBrand(state, value) {
      state.solutionSelectorChoices.brand = value;
    },
    addProduct(state, value) {
      state.solutionSelectorChoices.product = value;
    },
    addProductType(state, value) {
      state.solutionSelectorChoices.productType = value;
    },
    resetSolutionSelectorChoices(state) {
      state.solutionSelectorChoices = {
        productType: {},
        brand: {},
        product: {},
      };
    },
    addSelectedSolution(state, { id, taxId }) {
      state.selectedSolution.id = id;
      state.selectedSolution.taxId = taxId;
    },
    resetSelectedSolution(state) {
      state.selectedSolution = {
        id: null,
        taxId: null,
      };
    },
    // change(state, value) {
    //   state.isOffline = value;
    // },
    // addOfflineBlueprints(state, value) {
    //   state.offlineBlueprints = [...state.offlineBlueprints, value];
    // },
    // removeOfflineBlueprints(state, value) {
    //   state.offlineBlueprints = [
    //     ...state.offlineBlueprints.filter((x) => x != value),
    //   ];
    // },
    // updateMarker(state, value) {
    //   //Anwar: When marker is updated, notify the table
    //   //state.markers = [...state.markers.filter(x => x.id !== value.id), value]
    //   // console.log("Updating marker ", value);
    //   state.markers.filter((x) => {
    //     if (x.id === value.id) {
    //       x.isApproved = true;
    //       value.markerXSolutions.forEach((mxs) => {
    //         if (mxs.attachments.length > 0) {
    //           if (mxs.attachments[0].approved == false) {
    //             x.isApproved = 1;
    //             mxs.isApproved = 1;
    //           } else {
    //             x.isApproved = 2;
    //             mxs.isApproved = 2;
    //           }
    //         } else {
    //           x.isApproved = 0;
    //           mxs.isApproved = 0;
    //         }
    //       });
    //       // console.log("Updating marker ", value);
    //       //Bart, 13/6/19: manual setting of isApproved flag based on picture still needed
    //       Object.assign(x, value);
    //     }
    //   });
    // },
    // addOrUpdateOffForm(state, value) {
    //   if (state.offForm.find((x) => x.marker.id == value.marker.id)) {
    //     state.offForm = state.offForm.filter(
    //       (x) => x.marker.id != value.marker.id
    //     );
    //     state.offForm.push(value);
    //   } else {
    //     state.offForm.push(value);
    //   }
    // },
    // highlight(state, value) {
    //   state.markers = [
    //     ...state.markers.filter((x) => {
    //       if (x.id !== value.id) {
    //         x.highlight = false;
    //         return x;
    //       }
    //     }),
    //     value,
    //   ];
    // },
    // unHighlight(state, value) {
    //   state.markers = [
    //     ...state.markers.filter((x) => {
    //       if (x.id !== value.id) {
    //         x.highlight = false;
    //         return x;
    //       }
    //     }),
    //     value,
    //   ];
    // },
    // select(state, value) {
    //   if (value === null) {
    //     state.markers = [
    //       ...state.markers.filter((x) => {
    //         x.draggable = false;
    //         x.highlight = false;
    //         return x;
    //       }),
    //     ];
    //   } else {
    //     state.markers = [
    //       ...state.markers.filter((x) => {
    //         if (x.id !== value.id) {
    //           x.draggable = false;
    //           x.highlight = false;
    //           return x;
    //         }
    //       }),
    //       value,
    //     ];
    //   }
    // },
    // AddAndSetUIelements(state, value) {
    //   let markerui = {
    //     xRect: value.coordX - 5,
    //     yRect: value.coordY - 5,
    //     xStartLine: value.coordX,
    //     yStartLine: value.coordY,
    //     xEndLine:
    //       value.coordX <= value.coordX2
    //         ? value.coordX2 - 20
    //         : value.coordX2 + 20,
    //     yEndLine: value.coordY2,
    //     xCircle: value.coordX2,
    //     yCircle: value.coordY2,
    //     xLabel: value.coordX2 - 17.5,
    //     yLabel: value.coordY2 - 8.5,
    //     label: value.number,
    //     highlight: false,
    //     draggable: false,
    //   };
    //   state.markers = [
    //     ...state.markers.filter((x) => x.id !== value.id),
    //     { ...value, ...markerui },
    //   ];
    //   if (state.markers.length == 1) {
    //     markerui.draggable = true;
    //     state.markers = [
    //       ...state.markers.filter((x) => x.id !== value.id),
    //       { ...value, ...markerui },
    //     ];
    //   }
    // },
    // offAddAndSetUIelements(state, value) {
    //   let markerui = {
    //     xRect: value.coordX - 5,
    //     yRect: value.coordY - 5,
    //     xStartLine: value.coordX,
    //     yStartLine: value.coordY,
    //     xEndLine:
    //       value.coordX <= value.coordX2
    //         ? value.coordX2 - 20
    //         : value.coordX2 + 20,
    //     yEndLine: value.coordY2,
    //     xCircle: value.coordX2,
    //     yCircle: value.coordY2,
    //     xLabel: value.coordX2 - 17.5,
    //     yLabel: value.coordY2 - 8.5,
    //     label: value.number,
    //     highlight: false,
    //     draggable: false,
    //   };
    //   state.offline.offMarkers = [
    //     ...state.offline.offMarkers.filter((x) => x.id !== value.id),
    //     { ...value, ...markerui },
    //   ];
    //   if (state.offline.offMarkers.length == 1) {
    //     markerui.draggable = true;
    //     state.offline.offMarkers = [
    //       ...state.offline.offMarkers.filter((x) => x.id !== value.id),
    //       { ...value, ...markerui },
    //     ];
    //   }
    // },
  },
  getters: {
    // isOffline: (state) => state.isOffline,
    //Bart, 15/5/2019: map viewmodel Marker onto Marker ready for canvas rendering
    //Anwar, 29/05/2019: no getter used, because you can't watch changes(highlight)
    // markersui: state => state.markers.map(function(e) {
    //     return {
    //         id: e.id,
    //         xRect: e.coordX - 5,
    //         yRect: e.coordY - 5,
    //         xStartLine: e.coordX,
    //         yStartLine: e.coordY,
    //         xEndLine: e.coordX <= e.coordX2 ? e.coordX2 - 40 : e.coordX2 + 40,
    //         yEndLine: e.coordY2,
    //         xCircle: e.coordX2,
    //         yCircle: e.coordY2,
    //         xLabel: e.coordX2 - 7.5,
    //         yLabel: e.coordY2 - 12.5,
    //         label: e.name,
    //         highlight: false
    //     }
    // }),
    // markers: (state) =>
    //   state.markers.sort(function (a, b) {
    //     return a.number - b.number;
    //   }),
  },
});
