<template>
  <div>
    <v-autocomplete
      hide-details="none"
      :loading="loading"
      :search-input.sync="search"
      v-model="selected"
      @change="sortSearchAfterSelect"
      :items="data"
      clearable
    >
      <template v-slot:prepend>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="changeIcon" v-bind="attrs" v-on="on">
              {{ searchForNumber ? icons[0] : icons[1] }}
            </v-icon>
          </template>
          <span>
            {{
              searchForNumber
                ? $t("searchingForNumber")
                : $t("searchingForName")
            }}
          </span>
        </v-tooltip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import ajax from "@/services/service.oryx.js";

export default {
  data() {
    return {
      icons: ["mdi-pound", "mdi-alphabetical"],
      searchForNumber: true,
      selected: null,
      loading: false,
      searchTimeOut: null,
      search: "",
      data: [],
      recentlySelected: false,
    };
  },
  computed: {},
  methods: {
    changeIcon() {
      this.searchForNumber = !this.searchForNumber;
      this.data = [];
      this.selected = null;
      if (this.search) {
        this.sortSearchAfterDelay();
      }
    },
    // When typing in autocomplete
    sortSearchAfterDelay() {
      if (this.searchForNumber) {
        return this.getSearchForNumber();
      } else {
        return this.getSearchForName();
      }
    },
    getSearchForNumber() {
      if (this.search && !this.recentlySelected) {
        if (this.search == "") {
          this.data = [];
        } else {
          // console.log(this.search);
          this.loading = true;
          ajax
            .getSearchByNumber(this.search)
            .then((res) => {
              // console.log(res.data);
              if (res.data.ProductId) {
                return this.selectedSolutionByNumber(res.data);
              }
              this.data = res.data;
              if (this.data.length == 0) {
                console.log("No results found");
                this.$noty.error("No results found"); //this.$t("userNotFound"));
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } else {
        this.recentlySelected = false;
      }
    },
    getSearchForName() {
      if (this.search) {
        if (this.search == "") {
          this.data = [];
        } else {
          // console.log(this.search);
          this.loading = true;
          ajax
            .getSearchByName(this.search, "NL")
            .then((res) => {
              // console.log(res.data);
              if (res.data.ProductId) {
                return this.selectedSolutionByName(res.data);
              }
              this.data = res.data;
              if (this.data.length == 0) {
                console.log("No results found");
                this.$noty.error("No results found"); //this.$t("userNotFound"));
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },

    // When Selecting a result
    sortSearchAfterSelect() {
      if (this.selected) {
        if (this.searchForNumber) {
          return this.selectThisNumber();
        }
      } else {
        this.data = [];
      }
    },
    selectThisNumber() {
      this.recentlySelected = true;
      this.loading = true;
      ajax
        .getNumberResults(this.selected)
        .then((res) => {
          // console.log(res.data);
          return this.selectedSolutionByNumber(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // selectThisName() {
    //   this.loading = true;
    //   ajax
    //     .getNameResults(this.selected)
    //     .then((res) => {
    //       // console.log(res.data);
    //       return this.selectedSolutionByName(res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },

    // Go To Detail Page
    selectedSolutionByNumber(solutionByNumber) {
      console.log(`Routing ongoing...`);
      this.$store.commit("resetSolutionSelectorChoices");
      this.$store.commit("addSelectedSolution", {
        id: solutionByNumber.ProductId,
        taxId: solutionByNumber.Id,
      });

      if (this.$route.name != "Detail") {
        this.$router.push({
          name: "Detail",
          params: {
            // id: solutionByNumber.ProductId,
            // taxId: solutionByNumber.Id,
            isFirelog: false, //this.isFirelog,
          },
        });
      }
    },

    // Go To SolutionSelector
    selectedSolutionByName(solutionByName) {
      console.log(`Routing ongoing...`);
      console.log(solutionByName);
      this.$store.commit("resetSolutionSelectorChoices");
      this.$store.commit("addBrand", solutionByName.Brand);
      this.$store.commit("addProduct", solutionByName.Product);
      console.log(this.$store.state.solutionSelectorChoices);

      if (this.$route.name != "Selector") {
        this.$router.push({
          name: "Selector",
        });
      }
    },
  },
  watch: {
    search() {
      clearTimeout(this.searchTimeOut);
      this.searchTimeOut = setTimeout(() => {
        this.sortSearchAfterDelay();
      }, 500);
    },
  },
};
</script>

<style scoped></style>
