<template>
  <v-app>
    <template v-if="$store.state.loggedIn">
      <v-app-bar app color="primary" dark>
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          v-if="$vuetify.breakpoint.xsOnly"
        ></v-app-bar-nav-icon>
        <div class="d-flex align-center">
          <img
            class="shrink mr-2"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="40"
          />
        </div>
        <!-- <v-app-bar-title style="width: auto">
          {{ $t("solutionselector") }}
        </v-app-bar-title> -->
        <!-- <v-spacer /> -->
        <!-- <template slot="extension" v-if="$vuetify.breakpoint.mdAndUp"> -->
        <v-tabs v-if="$vuetify.breakpoint.smAndUp">
          <v-tab v-if="$vuetify.breakpoint.mdAndUp" to="/">
            {{ $t("solutionSelectorBy") }}
            <img
              class="mr-2"
              contain
              src="@/assets/IsopartnerLogo-White.png"
              transition="scale-transition"
              height="20"
          /></v-tab>
          <!-- <v-tab>Tab 1</v-tab>
          <v-tab>Tab 2</v-tab>
          <v-tab>Tab 3</v-tab> -->
          <!-- SHOW ICONS WHEN ON MOBILE, ELSE SHOW TEXT -->
          <v-tab
            v-for="item in navBarItems"
            :key="item.key"
            :to="item.to"
            class="noTransform"
          >
            <v-icon color="white" v-if="$vuetify.breakpoint.smAndDown">
              {{ item.icon }}
            </v-icon>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              {{ $t(item.localeText) }}
            </template>
          </v-tab>
        </v-tabs>
        <!-- </template> -->
        <!-- <v-btn @click="choosingLanguage = true" text rounded>
          {{ language }}
        </v-btn> -->
        <searchField />
        <v-btn @click="logout" text rounded>
          <v-icon color="white" small class="mr-2">mdi-logout</v-icon>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            {{ $t("signout") }}
          </template>
        </v-btn>
      </v-app-bar>

      <!-- language menu -->
      <v-dialog v-model="choosingLanguage">
        <v-card>
          <v-card-title> {{ $t("chooseLanguage") }} </v-card-title>
          <v-card-text>
            <v-radio-group v-model="selectedLanguage">
              <v-radio
                v-for="lang in languages"
                :key="lang.key"
                :label="lang.label"
                :value="lang.value"
              ></v-radio>
            </v-radio-group>
            <v-btn @click="closeLanguage" color="primary" class="mr-2">
              {{ $t("close") }}
            </v-btn>
            <v-btn @click="chooseLanguage" color="primary">
              {{ $t("save") }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- hamburger menu -->
      <v-navigation-drawer
        v-model="drawer"
        style="position: fixed; top: 0; left: 0; overflow-y: scroll"
        temporary
      >
        <v-list nav dense>
          <v-list-item-group v-model="group" active-class="secondary">
            <v-list-item
              v-for="item in navBarItems"
              :key="item.key"
              :to="item.to"
            >
              <v-list-item-icon>
                <v-icon>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t(item.localeText) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </template>

    <v-main :class="loggedIn ? '' : 'light blue'">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import "vuejs-noty/dist/vuejs-noty.css";
import "vuetify/dist/vuetify.min.css";
import searchField from "@/components/SearchField.vue";

export default {
  name: "App",
  components: {
    searchField,
  },
  beforeMount() {
    if (localStorage.getItem("auth") != null) {
      this.$store.state.loggedIn = true;
    } else {
      this.$store.state.loggedIn = false;
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    },
    language() {
      return localStorage.getItem("language");
    },
  },
  data() {
    return {
      languages: [
        //{ key: "lan00", label: "English", value: "EN" },
        { key: "lan01", label: "Nederlands", value: "NL" },
        // { key: "lan02", label: "Deutsch", value: "DE" },
      ],
      selectedLanguage: localStorage.getItem("language"),
      choosingLanguage: false,
      navBarItems: [
        {
          key: 1,
          icon: "mdi-home",
          localeText: "home",
          to: "/",
          showText: false,
        },
        // {
        //   key: 2,
        //   icon: "mdi-feature-search",
        //   localeText: "solutionselector",
        //   to: "/Choice",
        //   showText: false,
        // },
        {
          key: 3,
          icon: "mdi-star",
          localeText: "favorites",
          to: "/Favorites",
          showText: false,
        },
        // {
        //   key: 4,
        //   icon: "mdi-help",
        //   localeText: "FAQ",
        //   to: "/Faq",
        //   showText: false,
        // },
        {
          key: 4,
          icon: "mdi-information-variant",
          localeText: "contact",
          to: "/contact",
          showText: false,
        },
      ],
      drawer: false,
      group: null,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("auth");
      this.$store.state.loggedIn = false;
      this.$router.push({ name: "Login" });
    },
    chooseLanguage() {
      // console.log("choosing language");
      localStorage.setItem("language", this.selectedLanguage);
      this.choosingLanguage = false;
      this.$forceUpdate;
    },
    closeLanguage() {
      this.choosingLanguage = false;
      this.selectedLanguage = localStorage.getItem("language");
      // console.log("closing language");
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped>
.noTransform {
  text-transform: none;
}
</style>
