import axios from "axios";
import settings from "../settings/settings.js";
import router from "../router/index.js";

const ajax = axios.create({
  baseURL: settings.baseUrlApi,
});
ajax.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("auth");
    if (navigator.onLine) {
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    } else {
      return false;
    }
  },
  (error) => {
    console.log(error);
    if (error.response.status === 401) {
      // localStorage.removeItem("auth");
      // localStorage.removeItem("user");
      // this.$router.go();
    }
    return Promise.reject(error);
  }
);

ajax.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("auth");
      localStorage.removeItem("user");
      router.go();
    }
    // if(error.response.status === 400 || error.response.status === 404)
    // {
    //     window.location.href = "/Error"
    // }
    else if (error.response.status === 404) {
      router.push({ name: "Error", params: { status: 404 } });
    } else if (error.response.status === 403) {
      router.push({ name: "Error", params: { status: 403 } });
    } else {
      //window.location.href = `${settings.baseUrl}/Error`
    }
    return Promise.reject(error);
  }
);

export default {
  // //GET
  // getProjects() {
  //     return ajax.get(`${settings.baseUrlApi}/Projects`)
  // },

  // getProject(id) {
  //     return ajax
  //         .get(settings.baseUrlApi + `/Projects/${id}`)
  // },

  // getBuildingComponents(id) {
  //     return ajax
  //         .get(settings.baseUrlApi + `/BuildingComponentsByProjectId/${id}`)
  // },

  // getBuildingComponent(id) {
  //     return ajax
  //         .get(settings.baseUrlApi + `/BuildingComponents/${id}`)
  // },
  // getBlueprints(id) {
  //     return ajax
  //         .get(settings.baseUrlApi + `/BlueprintsByBuildingComponentId/${id}`)
  // },

  // getBlueprint(id) {
  //     return ajax
  //         .get(settings.baseUrlApi + `/Blueprints/${id}`)
  // },

  // getMarkers(id) {
  //     return ajax
  //         .get(settings.baseUrlApi + `/MarkersByBlueprintId/${id}?languageCode=${localStorage.getItem('language')}`)
  // },

  // getMarker(id) {
  //     return ajax
  //         .get(settings.baseUrlApi + `/Markers/${id}?languageCode=${localStorage.getItem('language')}`)
  // },
  // getAttributes(offline) {
  //     return ajax.get(settings.baseUrlApi + `/QRLogAttributes?languageCode=${localStorage.getItem('language')}&offline=${offline}`)
  // },

  // getUnusedAttributes(markerId) {
  //     return ajax.get(settings.baseUrlApi + `/QRLogAttributesUnused/${markerId}?languageCode=${localStorage.getItem('language')}`)
  // },
  // getUnusedAttributes2(markerxSolutionsId) {
  //     return ajax.get(settings.baseUrlApi + `/QRLogAttributesUnused2/${markerxSolutionsId}?languageCode=${localStorage.getItem('language')}`)
  // },

  // getAttributeClasses() {
  //     return ajax.get(settings.baseUrlApi + `/QRLogAttributeClasses?languageCode=${localStorage.getItem('language')}`)
  // },
  // showValues(attributeId) {
  //     return ajax
  //         .get(settings.baseUrlApi + `/QRLogAttributeListValuesByAttributeId/${attributeId}?languageCode=${localStorage.getItem('language')}`)
  // },
  // getLanguages() {
  //     return ajax
  //         .get(settings.baseUrlApi + "/Languages")
  // },

  // // getProductSolutions(includeEcommerce = false) {
  // //     return ajax
  // //         .get(settings.baseUrlApi + `/ProductSolutions/${includeEcommerce}`)
  // // }
  // getProductSolutions(includeEcommerce = false, search = "") {
  //     return ajax
  //         .get(settings.baseUrlApi + `/ProductSolutions/${includeEcommerce}?search=${search}&languageCode=${localStorage.getItem("language")}`)
  // },

  // getPIMProductSolutions() {
  //     return ajax
  //         .get(settings.baseUrlApi + `/PIMProductSolutions?languageCode=${localStorage.getItem("language")}`)
  // },

  // getLocalProductSolutions() {
  //     return ajax
  //         .get(settings.baseUrlApi + `/Products`)
  // },

  // getProductsBySolutionId(productSolutionId, includeEcommerce = false) {
  //     return ajax
  //         .get(settings.baseUrlApi + `/ProductSolutions/${productSolutionId}?includeEcommerce=${includeEcommerce}&languageCode=${localStorage.getItem("language")}`)
  // },

  // getProductById(id) {
  //     return ajax
  //         .get(settings.baseUrlApi + `/Products/${id}`)
  // },

  // getProducts(includeEcommerce = false, search) {
  //     return ajax
  //         .get(settings.baseUrlApi + `/Products/${includeEcommerce}?search=${search}&languageCode=${localStorage.getItem("language")}`)
  // },
  // syncAttributes() {
  //     return ajax
  //         .get(settings.baseUrlApi + "/SyncAttributes")
  // },

  // getMarkerByQRId(id, markerId) {
  //     return ajax
  //         .get(`${settings.baseUrlApi}/QR/${id}?markerId=${markerId}`)
  // },
  // getMarkerId(id) {
  //     return ajax
  //         .get(`${settings.baseUrlApi}/QR/${id}`)
  // },

  // getLog(form) {
  //     return ajax({
  //         url: `${settings.baseUrlApi}/Log`,
  //         method: 'POST',
  //         data: form,
  //         headers: {
  //             'content-type': `multipart/form-data; boundary=${form._boundary}`,
  //         },
  //         responseType: 'blob',
  //     })
  // },
  // downloadQRStickers(amount, logo) {
  //     return ajax({
  //         url: `${settings.baseUrlApi}/QRStickers?amount=${amount}&logo=${logo}`,
  //         method: 'GET',
  //         responseType: 'blob',
  //     })
  // },
  // downloadExcelExport(projectId = '', buildingComponentId = '', blueprintId = '') {
  //     return ajax({
  //         url: `${settings.baseUrlApi}/ExcelExport?projectId=${projectId}&buildingComponentId=${buildingComponentId}&blueprintId=${blueprintId}&lc=${localStorage.getItem("language")}`,
  //         method: 'GET',
  //         responseType: 'blob',
  //     })
  // },

  // getTodo() {
  //     return ajax
  //         .get(settings.baseUrlApi + `/Todo`)
  // },

  // getReports() {
  //     return ajax
  //         .get(settings.baseUrlApi + `/Report`)
  // },
  // downloadLogbook(projectId = '', buildingComponentId = '') {
  //     return ajax({
  //         url: settings.baseUrlApi + `/DownloadLogbook?projectId=${projectId}&buildingComponentId=${buildingComponentId}&languageCode=${localStorage.getItem("language")}`,
  //         method: 'GET',
  //         responseType: 'blob'
  //     })
  // },

  // getHelp(){
  //     return ajax
  //         .get(settings.baseUrlApi + '/Help?languageCode='+localStorage.getItem("language"))
  // },
  // getStickerTemplate(){
  //     return ajax
  //         .get(settings.baseUrlApi + '/StickerTemplates')
  // },

  // //POST
  // addProject(project) {
  //     return ajax
  //         .post(`${settings.baseUrlApi}/Projects`, project)
  // },

  // addBuildingComponent(buildingComponent) {
  //     return ajax
  //         .post(settings.baseUrlApi + `/BuildingComponents`, buildingComponent)
  // },

  // addBlueprint(blueprint) {
  //     return ajax
  //         .post(settings.baseUrlApi + `/Blueprints`, blueprint)
  // },

  // addBlueprintFile(blueprintId, blueprintFile) {
  //     return ajax
  //         .post(
  //             settings.baseUrlApi + "/Blueprints/upload?blueprintId=" +
  //             blueprintId,
  //             blueprintFile
  //         )
  // },

  // addMarker(marker) {
  //     return ajax
  //         .post(settings.baseUrlApi + `/Markers`, marker)
  // },

  // uploadMarkerAttachment(formData, markerId) {
  //     return ajax
  //         .post(settings.baseUrlApi + `/AttachmentsFile?markerId=${markerId}`, formData);
  // },

  // uploadMarkerAttachmentByCamera(attachment) {
  //     return ajax
  //         .post(settings.baseUrlApi + `/Attachments?languageCode=${localStorage.getItem('language')}`, attachment);
  // },

  // postAttributesForMarker(qrLogAttributeXMarkers) {
  //     return ajax
  //         .post(
  //             settings.baseUrlApi + "/QRLogAttributeXMarkersList",
  //             qrLogAttributeXMarkers
  //         )
  // },

  // saveAttribute(attribute) {
  //     return ajax
  //         .post(settings.baseUrlApi + `/QRLogAttributes?languageCode=${localStorage.getItem('language')}`, attribute)
  // },
  // saveAttributeValue(data) {
  //     return ajax
  //         .post(settings.baseUrlApi + `/QRLogAttributeListValues?languageCode=${localStorage.getItem('language')}`, data)
  // },
  // addInstaller(installer) {
  //     return ajax
  //         .post(settings.baseUrlApi + "/Installers", installer)
  // },
  // addSolution(solution) {
  //     return ajax
  //         .post(settings.baseUrlApi + "/ProductSolutions", solution)
  // },

  // addProduct(solutionId, product) {
  //     return ajax
  //         .post(settings.baseUrlApi + `/Products?productSolutionId=${solutionId}`, product)
  // },

  login(creadentials) {
    return axios.post(
      settings.iHubBaseUrlApi + "/Authentication/Login",
      creadentials
    );
  },
  // updateAttributesPosition(attributes) {
  //     return ajax
  //         .post(settings.baseUrlApi + '/SortAttributes', attributes)
  // },

  // addSelectedProducts(selectedProducts) {
  //     return ajax
  //         .post(settings.baseUrlApi + `/PostSelectedProducts`, selectedProducts)
  // },

  // addReport(report) {
  //     return ajax
  //         .post(settings.baseUrlApi + `/Report`, report)
  // },

  // addAttachment(form){
  //     return ajax.post(settings.baseUrlApi + `ProductAttachments`, form)
  // },

  // uploadAttachment(form) {
  //     return ajax({
  //         url: `${settings.baseUrlApi}/UploadProductAttachment`,
  //         method: 'POST',
  //         data: form,
  //         headers: {
  //             'content-type': `multipart/form-data; boundary=${form._boundary}`,
  //         },
  //     })
  // },

  // uploadLogo(form){
  //     return ajax({
  //         url: `${settings.baseUrlApi}/StickerTemplates`,
  //         method: 'POST',
  //         data: form,
  //         headers: {
  //             'content-type': `multipart/form-data; boundary=${form._boundary}`,
  //         },
  //     })
  // },

  // //DELETE
  // deleteProject(id) {
  //     return ajax.delete(`${settings.baseUrlApi}/Projects/${id}`)
  // },
  // deleteBuildingComponents(id) {
  //     return ajax
  //         .delete(settings.baseUrlApi + `/BuildingComponents/${id}`)
  // },
  // deleteBlueprint(id) {
  //     return ajax
  //         .delete(settings.baseUrlApi + `/Blueprints/${id}`)
  // },
  // deleteMarker(id) {
  //     return ajax
  //         .delete(settings.baseUrlApi + `/Markers/${id}`)
  // },
  // deleteAttachment(id) {
  //     return ajax
  //         .delete(settings.baseUrlApi + `/Attachments/${id}`)
  // },
  // deleteValues(valueId) {
  //     return ajax
  //         .delete(settings.baseUrlApi + `/QRLogAttributeListValues/${valueId}`)
  // },

  // deleteAttribute(id) {
  //     return ajax
  //         .delete(settings.baseUrlApi + `/QRLogAttributes/${id}`)
  // },

  // deleteMarkerXSolution(id) {
  //     return ajax.delete(settings.baseUrlApi + `/MarkerXSolutions/${id}`)
  // },
  // deleteSolutionXproduct(id, fk) {
  //     return ajax.post(settings.baseUrlApi + `/ProductSolutions?id=${id}&fk=${fk}`, id)
  //     //.post(settings.baseUrlApi + `/DeleteProductSolutions?fk=${fk}`, id)
  // },
  // deleteProductSolution(id){
  //     return ajax.delete(settings.baseUrlApi + `/ProductSolutions/${id}`)
  // },

  // deleteProductAttachment(id) {
  //     return ajax.delete(settings.baseUrlApi + `/DeleteProductAttachment/${id}`)
  // },

  // deleteReport(id) {
  //     return ajax.delete(settings.baseUrlApi + `/Report/${id}`)
  // },

  //     //PUT
  //     updateProject(project) {
  //         return ajax.put(settings.baseUrlApi + `/Projects/${project.id}`, project)
  //     },

  //     updateBlueprint(blueprint) {
  //         return ajax
  //             .put(settings.baseUrlApi + `/Blueprints/${blueprint.id}`, blueprint)
  //     },
  //     updateMarker(marker) {
  //         return ajax
  //             .put(settings.baseUrlApi + `/Markers/${marker.id}`, marker)
  //     },
  //     updateAttributesForMarker(qrLogAttributeXMarkers) {
  //         return ajax
  //             .put(
  //                 settings.baseUrlApi + "/QRLogAttributeXMarkersList",
  //                 qrLogAttributeXMarkers
  //             )
  //     },

  //     updateAttribute(qRLogAttributes) {
  //         return ajax
  //             .put(`${settings.baseUrlApi}/UpdateQRLogAttributes?languageCode=${localStorage.getItem('language')}`, qRLogAttributes)
  //     },
  //     updateAttributeValues(qRLogAttributeValues) {
  //         return ajax
  //             .put(`${settings.baseUrlApi}/UpdateQRLogAttributeValues?languageCode=${localStorage.getItem('language')}`, qRLogAttributeValues)
  //     },
  //     changeVisibility(attribute) {
  //         return ajax
  //             .put(`${settings.baseUrlApi}/QRLogAttributes/${attribute.id}`, attribute)
  //     },
  //     updateSolution(solution) {
  //         return ajax
  //             .put(settings.baseUrlApi + `/Products/${solution.id}`, solution)
  //     },
  //     lockBlueprint(id) {
  //         return ajax.get(settings.baseUrlApi + `/LockBlueprint/${id}`)
  //     },
  //     hidePIMSolution(id) {
  //         return ajax.get(settings.baseUrlApi + `/HidePIMProductSolutions/${id}`)
  //     },
  //     unhidePIMSolution(id) {
  //         return ajax.get(settings.baseUrlApi + `/UnHidePIMProductSolutions/${id}`)
  //     },
  //     uploadBlueprintSnapshot(blueprintFile) {
  //         return ajax
  //             .put(
  //                 settings.baseUrlApi + "/Blueprints/uploadSnapshot?blueprintFileId=" +
  //                 blueprintFile.id,
  //                 blueprintFile
  //             )
  //     },
};
