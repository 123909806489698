const settings = {
  //baseUrlApi: "http://localhost:60385/api",
  // baseUrlApi: 'https://d1780570.ngrok.io/api',
  baseUrlApi: "https://firelog-api.ipcom.be/api",
  //iHubBaseUrlApi: "http://localhost:64406/api",
  // iHubBaseUrlApi: "http://1fc94b06.ngrok.io/api",
  iHubBaseUrlApi: "https://ihub-api.ipcom.be/api",
  // baseUrl: "http://localhost:8080",
  // baseUrl: "https://firelog.ipcom.be",

  // signalRUrl: "http://localhost:60385/signalr",
  signalRUrl: "https://firelog-api.ipcom.be/signalr",

  //oryxUrlApi: "http://localhost:13279/api",
  oryxUrlApi: "https://pim.oryx.uat.ipcomdigital.eu/api",

  // admin: 10,
  // readonly: 11,
  // installer: 17,

  admin: 7,
  readonly: 8,
  installer: 14,

  firelogapplicationId: 2,
};

export default settings;
