import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueNoty from "vuejs-noty";

import messages from "@/settings/locales.js";
import VueI18n from "vue-i18n";

import service from "@/services/service.js";
import qs from "qs";

Vue.use(VueI18n);

Vue.use(VueNoty, {
  layout: "bottomRight",
  theme: "nest",
});

Vue.prototype.$http = service;
Vue.prototype.$qs = qs;

Vue.config.productionTip = false;
let language = "NL";//"localStorage.getItem("language");
if (!language) language = "NL";
const i18n = new VueI18n({
  locale: language,
  fallbackLocale: "NL",
  messages,
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
